import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import type { ProductKey } from '@apple/features/product/models/product';

import { getProduct, getProductAudits } from '../api/management';

export const productQueryKeys = {
	product: (itemId: ProductKey) => ['product', itemId],
	audit: (itemId: ProductKey) => ['product', itemId, 'audit'],
};

export const retailProductQueryOptions = {
	product: (itemId: ProductKey) =>
		queryOptions({
			queryKey: productQueryKeys.product(itemId),
			queryFn: () => getProduct(itemId),
			staleTime: 5 * 60 * 1000, // 5 minutes
		}),
	audit: (itemId: ProductKey) =>
		queryOptions({
			queryKey: productQueryKeys.audit(itemId),
			queryFn: () => getProductAudits(itemId),
			placeholderData: keepPreviousData,
			staleTime: 5 * 60 * 1000, // 5 minutes
			initialData: [],
		}),
};
