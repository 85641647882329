import axios, { isAxiosError } from 'axios';

import { NotAuthenticatedError, ServerError } from '@apple/utils/api';
import type { UpdateProfileRequest } from '@apple/features/auth/models/user';

import type { Profile } from '../models/user';

/** @see AppleRetail.Web.Features.Profiles.ProfileController */
export async function getProfile(): Promise<Profile | null> {
	try {
		return (await axios.get<Profile>('/api/profile')).data;
	} catch (error) {
		if (error instanceof NotAuthenticatedError) {
			return null;
		}

		if (
			error instanceof ServerError &&
			isAxiosError(error.innerError) &&
			error.innerError.status === 401
		) {
			return null;
		}

		if (isAxiosError(error) && error.response?.status === 401) {
			return null;
		}

		throw error;
	}
}

export async function updateProfile(request: UpdateProfileRequest): Promise<Profile> {
	return (await axios.post<Profile>('/api/profile', request)).data;
}
