import { z } from 'zod';

/**
 * @see AppleRetail.StoreEvents.DataModels.StoreEventCategorySize
 */
export type StoreEventCategorySize = 'Regular' | 'Large' | 'HighProfile' | 'All';

/**
 * @see AppleRetail.Web.Areas.StoreEvents.Models.StoreEventCategoryModel
 */
export interface StoreEventCategory {
	id: string;
	title: string;
	isSizeSelectionEnabled: boolean;
	imageUrl: string;
	labelImageUrl: string;
	quantity: number;
}

/**
 * @see AppleRetail.Web.Areas.StoreEvents.ApiControllers.StoreEventCategoryApiController.StoreEventCategoryListingRequest
 */
export interface StoreEventCategoryListingRequest {
	currentPage: number;
	pageSize: number;
	searchText: string;
	filterOnCategories: boolean;
	categoryIds: string[];
}

/**
 * @see AppleRetail.StoreEvents.DataModels.StoreEventCategorySize
 */
export const storeEventCategorySizeSchema = z.enum([
	'Regular',
	'Large',
	'HighProfile',
	'All',
]) satisfies z.ZodType<StoreEventCategorySize>;
