/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as AuthedRouteImport } from './routes/_authed/route';
import { Route as AuthedIndexImport } from './routes/_authed/index';
import { Route as AuthedProfileImport } from './routes/_authed/profile';
import { Route as AuthedHelpImport } from './routes/_authed/help';
import { Route as publicSessionExpiredImport } from './routes/(public)/session-expired';
import { Route as publicResetPasswordImport } from './routes/(public)/reset-password';
import { Route as publicPrivacyImport } from './routes/(public)/privacy';
import { Route as publicLogoutImport } from './routes/(public)/logout';
import { Route as publicLoginImport } from './routes/(public)/login';
import { Route as publicForgotPasswordImport } from './routes/(public)/forgot-password';
import { Route as publicFirstLoginImport } from './routes/(public)/first-login';
import { Route as errorsNotFoundImport } from './routes/(errors)/not-found';
import { Route as AuthedCartRouteImport } from './routes/_authed/cart/route';
import { Route as AuthedAdminRouteImport } from './routes/_authed/_admin/route';
import { Route as AuthedCartIndexImport } from './routes/_authed/cart/index';
import { Route as AuthedOrdersSearchImport } from './routes/_authed/orders.search';
import { Route as AuthedOrdersPoNumberImport } from './routes/_authed/orders.$poNumber';
import { Route as AuthedCartStoresImport } from './routes/_authed/cart/stores';
import { Route as AuthedCartShippingImport } from './routes/_authed/cart/shipping';
import { Route as AuthedCartQuantitiesImport } from './routes/_authed/cart/quantities';
import { Route as AuthedCartProductsImport } from './routes/_authed/cart/products';
import { Route as AuthedAdminManageImport } from './routes/_authed/_admin/manage';
import { Route as publicResetPasswordFormImport } from './routes/(public)/reset-password_.form';
import { Route as publicFirstLoginFormImport } from './routes/(public)/first-login_.form';
import { Route as AuthedCartConfirmationSalesOrderNumberImport } from './routes/_authed/cart/confirmation.$salesOrderNumber';
import { Route as AuthedAdminReportsUsersImport } from './routes/_authed/_admin/reports/users';
import { Route as AuthedAdminReportsSupportRequestsImport } from './routes/_authed/_admin/reports/supportRequests';
import { Route as AuthedAdminReportsProductsImport } from './routes/_authed/_admin/reports/products';
import { Route as AuthedAdminReportsEmailsImport } from './routes/_authed/_admin/reports/emails';
import { Route as AuthedAdminManageUsersImport } from './routes/_authed/_admin/manage.users';
import { Route as AuthedAdminManageStoresImport } from './routes/_authed/_admin/manage.stores';
import { Route as AuthedAdminManageProductsImport } from './routes/_authed/_admin/manage.products';
import { Route as AuthedAdminManagePlantsImport } from './routes/_authed/_admin/manage.plants';
import { Route as AuthedAdminManageMarketsImport } from './routes/_authed/_admin/manage.markets';
import { Route as AuthedAdminManageCategoriesImport } from './routes/_authed/_admin/manage.categories';
import { Route as AuthedAdminManageCalendarsImport } from './routes/_authed/_admin/manage.calendars';
import { Route as AuthedAdminDistroUploadImport } from './routes/_authed/_admin/distro.upload';
import { Route as AuthedAdminDistroHistoryImport } from './routes/_authed/_admin/distro.history';
import { Route as AuthedAdminContentFaqImport } from './routes/_authed/_admin/content.faq';
import { Route as AuthedAdminContentAnnouncementsImport } from './routes/_authed/_admin/content.announcements';
import { Route as AuthedAdminApprovalsOrdersImport } from './routes/_authed/_admin/approvals.orders';
import { Route as AuthedAdminReportsEmailsEmailIdImport } from './routes/_authed/_admin/reports/emails_.$emailId';
import { Route as AuthedAdminManageUsersAddImport } from './routes/_authed/_admin/manage.users_.add';
import { Route as AuthedAdminManageUsersUserNameImport } from './routes/_authed/_admin/manage.users_.$userName';
import { Route as AuthedAdminManageStoresAddImport } from './routes/_authed/_admin/manage.stores_.add';
import { Route as AuthedAdminManageProductsAddImport } from './routes/_authed/_admin/manage.products_.add';
import { Route as AuthedAdminManageProductsItemIdImport } from './routes/_authed/_admin/manage.products_.$itemId';
import { Route as AuthedAdminManagePlantsWarehouseLocationIdImport } from './routes/_authed/_admin/manage.plants_.$warehouseLocationId';
import { Route as AuthedAdminManageMarketsAddImport } from './routes/_authed/_admin/manage.markets_.add';
import { Route as AuthedAdminManageMarketsMarketIdImport } from './routes/_authed/_admin/manage.markets_.$marketId';
import { Route as AuthedAdminManageCalendarsAddImport } from './routes/_authed/_admin/manage.calendars_.add';
import { Route as AuthedAdminManageCalendarsFiscalYearImport } from './routes/_authed/_admin/manage.calendars_.$fiscalYear';
import { Route as AuthedAdminContentFaqAddImport } from './routes/_authed/_admin/content.faq_.add';
import { Route as AuthedAdminContentFaqFaqIdImport } from './routes/_authed/_admin/content.faq_.$faqId';
import { Route as AuthedAdminContentAnnouncementsAddImport } from './routes/_authed/_admin/content.announcements_.add';
import { Route as AuthedAdminContentAnnouncementsAnnouncementIdImport } from './routes/_authed/_admin/content.announcements_.$announcementId';
import { Route as AuthedAdminApprovalsOrdersPoNumberImport } from './routes/_authed/_admin/approvals.orders_.$poNumber';
import { Route as AuthedAdminManageStoresStoreIdCountryCodeImport } from './routes/_authed/_admin/manage.stores_.$storeId_.$countryCode';

// Create/Update Routes

const AuthedRouteRoute = AuthedRouteImport.update({
  id: '/_authed',
  getParentRoute: () => rootRoute,
} as any);

const AuthedIndexRoute = AuthedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedProfileRoute = AuthedProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedHelpRoute = AuthedHelpImport.update({
  id: '/help',
  path: '/help',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const publicSessionExpiredRoute = publicSessionExpiredImport.update({
  id: '/(public)/session-expired',
  path: '/session-expired',
  getParentRoute: () => rootRoute,
} as any);

const publicResetPasswordRoute = publicResetPasswordImport.update({
  id: '/(public)/reset-password',
  path: '/reset-password',
  getParentRoute: () => rootRoute,
} as any);

const publicPrivacyRoute = publicPrivacyImport.update({
  id: '/(public)/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any);

const publicLogoutRoute = publicLogoutImport.update({
  id: '/(public)/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any);

const publicLoginRoute = publicLoginImport.update({
  id: '/(public)/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any);

const publicForgotPasswordRoute = publicForgotPasswordImport.update({
  id: '/(public)/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => rootRoute,
} as any);

const publicFirstLoginRoute = publicFirstLoginImport.update({
  id: '/(public)/first-login',
  path: '/first-login',
  getParentRoute: () => rootRoute,
} as any);

const errorsNotFoundRoute = errorsNotFoundImport.update({
  id: '/(errors)/not-found',
  path: '/not-found',
  getParentRoute: () => rootRoute,
} as any);

const AuthedCartRouteRoute = AuthedCartRouteImport.update({
  id: '/cart',
  path: '/cart',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedAdminRouteRoute = AuthedAdminRouteImport.update({
  id: '/_admin',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedCartIndexRoute = AuthedCartIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthedCartRouteRoute,
} as any);

const AuthedOrdersSearchRoute = AuthedOrdersSearchImport.update({
  id: '/orders/search',
  path: '/orders/search',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedOrdersPoNumberRoute = AuthedOrdersPoNumberImport.update({
  id: '/orders/$poNumber',
  path: '/orders/$poNumber',
  getParentRoute: () => AuthedRouteRoute,
} as any);

const AuthedCartStoresRoute = AuthedCartStoresImport.update({
  id: '/stores',
  path: '/stores',
  getParentRoute: () => AuthedCartRouteRoute,
} as any);

const AuthedCartShippingRoute = AuthedCartShippingImport.update({
  id: '/shipping',
  path: '/shipping',
  getParentRoute: () => AuthedCartRouteRoute,
} as any);

const AuthedCartQuantitiesRoute = AuthedCartQuantitiesImport.update({
  id: '/quantities',
  path: '/quantities',
  getParentRoute: () => AuthedCartRouteRoute,
} as any);

const AuthedCartProductsRoute = AuthedCartProductsImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => AuthedCartRouteRoute,
} as any);

const AuthedAdminManageRoute = AuthedAdminManageImport.update({
  id: '/manage',
  path: '/manage',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const publicResetPasswordFormRoute = publicResetPasswordFormImport.update({
  id: '/(public)/reset-password_/form',
  path: '/reset-password/form',
  getParentRoute: () => rootRoute,
} as any);

const publicFirstLoginFormRoute = publicFirstLoginFormImport.update({
  id: '/(public)/first-login_/form',
  path: '/first-login/form',
  getParentRoute: () => rootRoute,
} as any);

const AuthedCartConfirmationSalesOrderNumberRoute =
  AuthedCartConfirmationSalesOrderNumberImport.update({
    id: '/confirmation/$salesOrderNumber',
    path: '/confirmation/$salesOrderNumber',
    getParentRoute: () => AuthedCartRouteRoute,
  } as any);

const AuthedAdminReportsUsersRoute = AuthedAdminReportsUsersImport.update({
  id: '/reports/users',
  path: '/reports/users',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminReportsSupportRequestsRoute =
  AuthedAdminReportsSupportRequestsImport.update({
    id: '/reports/supportRequests',
    path: '/reports/supportRequests',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminReportsProductsRoute = AuthedAdminReportsProductsImport.update(
  {
    id: '/reports/products',
    path: '/reports/products',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminReportsEmailsRoute = AuthedAdminReportsEmailsImport.update({
  id: '/reports/emails',
  path: '/reports/emails',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminManageUsersRoute = AuthedAdminManageUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageStoresRoute = AuthedAdminManageStoresImport.update({
  id: '/stores',
  path: '/stores',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageProductsRoute = AuthedAdminManageProductsImport.update({
  id: '/products',
  path: '/products',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManagePlantsRoute = AuthedAdminManagePlantsImport.update({
  id: '/plants',
  path: '/plants',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageMarketsRoute = AuthedAdminManageMarketsImport.update({
  id: '/markets',
  path: '/markets',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageCategoriesRoute =
  AuthedAdminManageCategoriesImport.update({
    id: '/categories',
    path: '/categories',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCalendarsRoute = AuthedAdminManageCalendarsImport.update(
  {
    id: '/calendars',
    path: '/calendars',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any,
);

const AuthedAdminDistroUploadRoute = AuthedAdminDistroUploadImport.update({
  id: '/distro/upload',
  path: '/distro/upload',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminDistroHistoryRoute = AuthedAdminDistroHistoryImport.update({
  id: '/distro/history',
  path: '/distro/history',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminContentFaqRoute = AuthedAdminContentFaqImport.update({
  id: '/content/faq',
  path: '/content/faq',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminContentAnnouncementsRoute =
  AuthedAdminContentAnnouncementsImport.update({
    id: '/content/announcements',
    path: '/content/announcements',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminApprovalsOrdersRoute = AuthedAdminApprovalsOrdersImport.update(
  {
    id: '/approvals/orders',
    path: '/approvals/orders',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminReportsEmailsEmailIdRoute =
  AuthedAdminReportsEmailsEmailIdImport.update({
    id: '/reports/emails_/$emailId',
    path: '/reports/emails/$emailId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminManageUsersAddRoute = AuthedAdminManageUsersAddImport.update({
  id: '/users_/add',
  path: '/users/add',
  getParentRoute: () => AuthedAdminManageRoute,
} as any);

const AuthedAdminManageUsersUserNameRoute =
  AuthedAdminManageUsersUserNameImport.update({
    id: '/users_/$userName',
    path: '/users/$userName',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageStoresAddRoute = AuthedAdminManageStoresAddImport.update(
  {
    id: '/stores_/add',
    path: '/stores/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any,
);

const AuthedAdminManageProductsAddRoute =
  AuthedAdminManageProductsAddImport.update({
    id: '/products_/add',
    path: '/products/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageProductsItemIdRoute =
  AuthedAdminManageProductsItemIdImport.update({
    id: '/products_/$itemId',
    path: '/products/$itemId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManagePlantsWarehouseLocationIdRoute =
  AuthedAdminManagePlantsWarehouseLocationIdImport.update({
    id: '/plants_/$warehouseLocationId',
    path: '/plants/$warehouseLocationId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageMarketsAddRoute =
  AuthedAdminManageMarketsAddImport.update({
    id: '/markets_/add',
    path: '/markets/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageMarketsMarketIdRoute =
  AuthedAdminManageMarketsMarketIdImport.update({
    id: '/markets_/$marketId',
    path: '/markets/$marketId',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCalendarsAddRoute =
  AuthedAdminManageCalendarsAddImport.update({
    id: '/calendars_/add',
    path: '/calendars/add',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminManageCalendarsFiscalYearRoute =
  AuthedAdminManageCalendarsFiscalYearImport.update({
    id: '/calendars_/$fiscalYear',
    path: '/calendars/$fiscalYear',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

const AuthedAdminContentFaqAddRoute = AuthedAdminContentFaqAddImport.update({
  id: '/content/faq_/add',
  path: '/content/faq/add',
  getParentRoute: () => AuthedAdminRouteRoute,
} as any);

const AuthedAdminContentFaqFaqIdRoute = AuthedAdminContentFaqFaqIdImport.update(
  {
    id: '/content/faq_/$faqId',
    path: '/content/faq/$faqId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any,
);

const AuthedAdminContentAnnouncementsAddRoute =
  AuthedAdminContentAnnouncementsAddImport.update({
    id: '/content/announcements_/add',
    path: '/content/announcements/add',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminContentAnnouncementsAnnouncementIdRoute =
  AuthedAdminContentAnnouncementsAnnouncementIdImport.update({
    id: '/content/announcements_/$announcementId',
    path: '/content/announcements/$announcementId',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminApprovalsOrdersPoNumberRoute =
  AuthedAdminApprovalsOrdersPoNumberImport.update({
    id: '/approvals/orders_/$poNumber',
    path: '/approvals/orders/$poNumber',
    getParentRoute: () => AuthedAdminRouteRoute,
  } as any);

const AuthedAdminManageStoresStoreIdCountryCodeRoute =
  AuthedAdminManageStoresStoreIdCountryCodeImport.update({
    id: '/stores_/$storeId_/$countryCode',
    path: '/stores/$storeId/$countryCode',
    getParentRoute: () => AuthedAdminManageRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authed': {
      id: '/_authed';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthedRouteImport;
      parentRoute: typeof rootRoute;
    };
    '/_authed/_admin': {
      id: '/_authed/_admin';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AuthedAdminRouteImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/cart': {
      id: '/_authed/cart';
      path: '/cart';
      fullPath: '/cart';
      preLoaderRoute: typeof AuthedCartRouteImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/(errors)/not-found': {
      id: '/(errors)/not-found';
      path: '/not-found';
      fullPath: '/not-found';
      preLoaderRoute: typeof errorsNotFoundImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/first-login': {
      id: '/(public)/first-login';
      path: '/first-login';
      fullPath: '/first-login';
      preLoaderRoute: typeof publicFirstLoginImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/forgot-password': {
      id: '/(public)/forgot-password';
      path: '/forgot-password';
      fullPath: '/forgot-password';
      preLoaderRoute: typeof publicForgotPasswordImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/login': {
      id: '/(public)/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof publicLoginImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/logout': {
      id: '/(public)/logout';
      path: '/logout';
      fullPath: '/logout';
      preLoaderRoute: typeof publicLogoutImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/privacy': {
      id: '/(public)/privacy';
      path: '/privacy';
      fullPath: '/privacy';
      preLoaderRoute: typeof publicPrivacyImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/reset-password': {
      id: '/(public)/reset-password';
      path: '/reset-password';
      fullPath: '/reset-password';
      preLoaderRoute: typeof publicResetPasswordImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/session-expired': {
      id: '/(public)/session-expired';
      path: '/session-expired';
      fullPath: '/session-expired';
      preLoaderRoute: typeof publicSessionExpiredImport;
      parentRoute: typeof rootRoute;
    };
    '/_authed/help': {
      id: '/_authed/help';
      path: '/help';
      fullPath: '/help';
      preLoaderRoute: typeof AuthedHelpImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/profile': {
      id: '/_authed/profile';
      path: '/profile';
      fullPath: '/profile';
      preLoaderRoute: typeof AuthedProfileImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/': {
      id: '/_authed/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof AuthedIndexImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/(public)/first-login_/form': {
      id: '/(public)/first-login_/form';
      path: '/first-login/form';
      fullPath: '/first-login/form';
      preLoaderRoute: typeof publicFirstLoginFormImport;
      parentRoute: typeof rootRoute;
    };
    '/(public)/reset-password_/form': {
      id: '/(public)/reset-password_/form';
      path: '/reset-password/form';
      fullPath: '/reset-password/form';
      preLoaderRoute: typeof publicResetPasswordFormImport;
      parentRoute: typeof rootRoute;
    };
    '/_authed/_admin/manage': {
      id: '/_authed/_admin/manage';
      path: '/manage';
      fullPath: '/manage';
      preLoaderRoute: typeof AuthedAdminManageImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/cart/products': {
      id: '/_authed/cart/products';
      path: '/products';
      fullPath: '/cart/products';
      preLoaderRoute: typeof AuthedCartProductsImport;
      parentRoute: typeof AuthedCartRouteImport;
    };
    '/_authed/cart/quantities': {
      id: '/_authed/cart/quantities';
      path: '/quantities';
      fullPath: '/cart/quantities';
      preLoaderRoute: typeof AuthedCartQuantitiesImport;
      parentRoute: typeof AuthedCartRouteImport;
    };
    '/_authed/cart/shipping': {
      id: '/_authed/cart/shipping';
      path: '/shipping';
      fullPath: '/cart/shipping';
      preLoaderRoute: typeof AuthedCartShippingImport;
      parentRoute: typeof AuthedCartRouteImport;
    };
    '/_authed/cart/stores': {
      id: '/_authed/cart/stores';
      path: '/stores';
      fullPath: '/cart/stores';
      preLoaderRoute: typeof AuthedCartStoresImport;
      parentRoute: typeof AuthedCartRouteImport;
    };
    '/_authed/orders/$poNumber': {
      id: '/_authed/orders/$poNumber';
      path: '/orders/$poNumber';
      fullPath: '/orders/$poNumber';
      preLoaderRoute: typeof AuthedOrdersPoNumberImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/orders/search': {
      id: '/_authed/orders/search';
      path: '/orders/search';
      fullPath: '/orders/search';
      preLoaderRoute: typeof AuthedOrdersSearchImport;
      parentRoute: typeof AuthedRouteImport;
    };
    '/_authed/cart/': {
      id: '/_authed/cart/';
      path: '/';
      fullPath: '/cart/';
      preLoaderRoute: typeof AuthedCartIndexImport;
      parentRoute: typeof AuthedCartRouteImport;
    };
    '/_authed/_admin/approvals/orders': {
      id: '/_authed/_admin/approvals/orders';
      path: '/approvals/orders';
      fullPath: '/approvals/orders';
      preLoaderRoute: typeof AuthedAdminApprovalsOrdersImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/announcements': {
      id: '/_authed/_admin/content/announcements';
      path: '/content/announcements';
      fullPath: '/content/announcements';
      preLoaderRoute: typeof AuthedAdminContentAnnouncementsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/faq': {
      id: '/_authed/_admin/content/faq';
      path: '/content/faq';
      fullPath: '/content/faq';
      preLoaderRoute: typeof AuthedAdminContentFaqImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/distro/history': {
      id: '/_authed/_admin/distro/history';
      path: '/distro/history';
      fullPath: '/distro/history';
      preLoaderRoute: typeof AuthedAdminDistroHistoryImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/distro/upload': {
      id: '/_authed/_admin/distro/upload';
      path: '/distro/upload';
      fullPath: '/distro/upload';
      preLoaderRoute: typeof AuthedAdminDistroUploadImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/manage/calendars': {
      id: '/_authed/_admin/manage/calendars';
      path: '/calendars';
      fullPath: '/manage/calendars';
      preLoaderRoute: typeof AuthedAdminManageCalendarsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/categories': {
      id: '/_authed/_admin/manage/categories';
      path: '/categories';
      fullPath: '/manage/categories';
      preLoaderRoute: typeof AuthedAdminManageCategoriesImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/markets': {
      id: '/_authed/_admin/manage/markets';
      path: '/markets';
      fullPath: '/manage/markets';
      preLoaderRoute: typeof AuthedAdminManageMarketsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/plants': {
      id: '/_authed/_admin/manage/plants';
      path: '/plants';
      fullPath: '/manage/plants';
      preLoaderRoute: typeof AuthedAdminManagePlantsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/products': {
      id: '/_authed/_admin/manage/products';
      path: '/products';
      fullPath: '/manage/products';
      preLoaderRoute: typeof AuthedAdminManageProductsImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/stores': {
      id: '/_authed/_admin/manage/stores';
      path: '/stores';
      fullPath: '/manage/stores';
      preLoaderRoute: typeof AuthedAdminManageStoresImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/users': {
      id: '/_authed/_admin/manage/users';
      path: '/users';
      fullPath: '/manage/users';
      preLoaderRoute: typeof AuthedAdminManageUsersImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/reports/emails': {
      id: '/_authed/_admin/reports/emails';
      path: '/reports/emails';
      fullPath: '/reports/emails';
      preLoaderRoute: typeof AuthedAdminReportsEmailsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/products': {
      id: '/_authed/_admin/reports/products';
      path: '/reports/products';
      fullPath: '/reports/products';
      preLoaderRoute: typeof AuthedAdminReportsProductsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/supportRequests': {
      id: '/_authed/_admin/reports/supportRequests';
      path: '/reports/supportRequests';
      fullPath: '/reports/supportRequests';
      preLoaderRoute: typeof AuthedAdminReportsSupportRequestsImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/reports/users': {
      id: '/_authed/_admin/reports/users';
      path: '/reports/users';
      fullPath: '/reports/users';
      preLoaderRoute: typeof AuthedAdminReportsUsersImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/cart/confirmation/$salesOrderNumber': {
      id: '/_authed/cart/confirmation/$salesOrderNumber';
      path: '/confirmation/$salesOrderNumber';
      fullPath: '/cart/confirmation/$salesOrderNumber';
      preLoaderRoute: typeof AuthedCartConfirmationSalesOrderNumberImport;
      parentRoute: typeof AuthedCartRouteImport;
    };
    '/_authed/_admin/approvals/orders_/$poNumber': {
      id: '/_authed/_admin/approvals/orders_/$poNumber';
      path: '/approvals/orders/$poNumber';
      fullPath: '/approvals/orders/$poNumber';
      preLoaderRoute: typeof AuthedAdminApprovalsOrdersPoNumberImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/announcements_/$announcementId': {
      id: '/_authed/_admin/content/announcements_/$announcementId';
      path: '/content/announcements/$announcementId';
      fullPath: '/content/announcements/$announcementId';
      preLoaderRoute: typeof AuthedAdminContentAnnouncementsAnnouncementIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/announcements_/add': {
      id: '/_authed/_admin/content/announcements_/add';
      path: '/content/announcements/add';
      fullPath: '/content/announcements/add';
      preLoaderRoute: typeof AuthedAdminContentAnnouncementsAddImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/faq_/$faqId': {
      id: '/_authed/_admin/content/faq_/$faqId';
      path: '/content/faq/$faqId';
      fullPath: '/content/faq/$faqId';
      preLoaderRoute: typeof AuthedAdminContentFaqFaqIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/content/faq_/add': {
      id: '/_authed/_admin/content/faq_/add';
      path: '/content/faq/add';
      fullPath: '/content/faq/add';
      preLoaderRoute: typeof AuthedAdminContentFaqAddImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/manage/calendars_/$fiscalYear': {
      id: '/_authed/_admin/manage/calendars_/$fiscalYear';
      path: '/calendars/$fiscalYear';
      fullPath: '/manage/calendars/$fiscalYear';
      preLoaderRoute: typeof AuthedAdminManageCalendarsFiscalYearImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/calendars_/add': {
      id: '/_authed/_admin/manage/calendars_/add';
      path: '/calendars/add';
      fullPath: '/manage/calendars/add';
      preLoaderRoute: typeof AuthedAdminManageCalendarsAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/markets_/$marketId': {
      id: '/_authed/_admin/manage/markets_/$marketId';
      path: '/markets/$marketId';
      fullPath: '/manage/markets/$marketId';
      preLoaderRoute: typeof AuthedAdminManageMarketsMarketIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/markets_/add': {
      id: '/_authed/_admin/manage/markets_/add';
      path: '/markets/add';
      fullPath: '/manage/markets/add';
      preLoaderRoute: typeof AuthedAdminManageMarketsAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/plants_/$warehouseLocationId': {
      id: '/_authed/_admin/manage/plants_/$warehouseLocationId';
      path: '/plants/$warehouseLocationId';
      fullPath: '/manage/plants/$warehouseLocationId';
      preLoaderRoute: typeof AuthedAdminManagePlantsWarehouseLocationIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/products_/$itemId': {
      id: '/_authed/_admin/manage/products_/$itemId';
      path: '/products/$itemId';
      fullPath: '/manage/products/$itemId';
      preLoaderRoute: typeof AuthedAdminManageProductsItemIdImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/products_/add': {
      id: '/_authed/_admin/manage/products_/add';
      path: '/products/add';
      fullPath: '/manage/products/add';
      preLoaderRoute: typeof AuthedAdminManageProductsAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/stores_/add': {
      id: '/_authed/_admin/manage/stores_/add';
      path: '/stores/add';
      fullPath: '/manage/stores/add';
      preLoaderRoute: typeof AuthedAdminManageStoresAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/users_/$userName': {
      id: '/_authed/_admin/manage/users_/$userName';
      path: '/users/$userName';
      fullPath: '/manage/users/$userName';
      preLoaderRoute: typeof AuthedAdminManageUsersUserNameImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/manage/users_/add': {
      id: '/_authed/_admin/manage/users_/add';
      path: '/users/add';
      fullPath: '/manage/users/add';
      preLoaderRoute: typeof AuthedAdminManageUsersAddImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
    '/_authed/_admin/reports/emails_/$emailId': {
      id: '/_authed/_admin/reports/emails_/$emailId';
      path: '/reports/emails/$emailId';
      fullPath: '/reports/emails/$emailId';
      preLoaderRoute: typeof AuthedAdminReportsEmailsEmailIdImport;
      parentRoute: typeof AuthedAdminRouteImport;
    };
    '/_authed/_admin/manage/stores_/$storeId_/$countryCode': {
      id: '/_authed/_admin/manage/stores_/$storeId_/$countryCode';
      path: '/stores/$storeId/$countryCode';
      fullPath: '/manage/stores/$storeId/$countryCode';
      preLoaderRoute: typeof AuthedAdminManageStoresStoreIdCountryCodeImport;
      parentRoute: typeof AuthedAdminManageImport;
    };
  }
}

// Create and export the route tree

interface AuthedAdminManageRouteChildren {
  AuthedAdminManageCalendarsRoute: typeof AuthedAdminManageCalendarsRoute;
  AuthedAdminManageCategoriesRoute: typeof AuthedAdminManageCategoriesRoute;
  AuthedAdminManageMarketsRoute: typeof AuthedAdminManageMarketsRoute;
  AuthedAdminManagePlantsRoute: typeof AuthedAdminManagePlantsRoute;
  AuthedAdminManageProductsRoute: typeof AuthedAdminManageProductsRoute;
  AuthedAdminManageStoresRoute: typeof AuthedAdminManageStoresRoute;
  AuthedAdminManageUsersRoute: typeof AuthedAdminManageUsersRoute;
  AuthedAdminManageCalendarsFiscalYearRoute: typeof AuthedAdminManageCalendarsFiscalYearRoute;
  AuthedAdminManageCalendarsAddRoute: typeof AuthedAdminManageCalendarsAddRoute;
  AuthedAdminManageMarketsMarketIdRoute: typeof AuthedAdminManageMarketsMarketIdRoute;
  AuthedAdminManageMarketsAddRoute: typeof AuthedAdminManageMarketsAddRoute;
  AuthedAdminManagePlantsWarehouseLocationIdRoute: typeof AuthedAdminManagePlantsWarehouseLocationIdRoute;
  AuthedAdminManageProductsItemIdRoute: typeof AuthedAdminManageProductsItemIdRoute;
  AuthedAdminManageProductsAddRoute: typeof AuthedAdminManageProductsAddRoute;
  AuthedAdminManageStoresAddRoute: typeof AuthedAdminManageStoresAddRoute;
  AuthedAdminManageUsersUserNameRoute: typeof AuthedAdminManageUsersUserNameRoute;
  AuthedAdminManageUsersAddRoute: typeof AuthedAdminManageUsersAddRoute;
  AuthedAdminManageStoresStoreIdCountryCodeRoute: typeof AuthedAdminManageStoresStoreIdCountryCodeRoute;
}

const AuthedAdminManageRouteChildren: AuthedAdminManageRouteChildren = {
  AuthedAdminManageCalendarsRoute: AuthedAdminManageCalendarsRoute,
  AuthedAdminManageCategoriesRoute: AuthedAdminManageCategoriesRoute,
  AuthedAdminManageMarketsRoute: AuthedAdminManageMarketsRoute,
  AuthedAdminManagePlantsRoute: AuthedAdminManagePlantsRoute,
  AuthedAdminManageProductsRoute: AuthedAdminManageProductsRoute,
  AuthedAdminManageStoresRoute: AuthedAdminManageStoresRoute,
  AuthedAdminManageUsersRoute: AuthedAdminManageUsersRoute,
  AuthedAdminManageCalendarsFiscalYearRoute:
    AuthedAdminManageCalendarsFiscalYearRoute,
  AuthedAdminManageCalendarsAddRoute: AuthedAdminManageCalendarsAddRoute,
  AuthedAdminManageMarketsMarketIdRoute: AuthedAdminManageMarketsMarketIdRoute,
  AuthedAdminManageMarketsAddRoute: AuthedAdminManageMarketsAddRoute,
  AuthedAdminManagePlantsWarehouseLocationIdRoute:
    AuthedAdminManagePlantsWarehouseLocationIdRoute,
  AuthedAdminManageProductsItemIdRoute: AuthedAdminManageProductsItemIdRoute,
  AuthedAdminManageProductsAddRoute: AuthedAdminManageProductsAddRoute,
  AuthedAdminManageStoresAddRoute: AuthedAdminManageStoresAddRoute,
  AuthedAdminManageUsersUserNameRoute: AuthedAdminManageUsersUserNameRoute,
  AuthedAdminManageUsersAddRoute: AuthedAdminManageUsersAddRoute,
  AuthedAdminManageStoresStoreIdCountryCodeRoute:
    AuthedAdminManageStoresStoreIdCountryCodeRoute,
};

const AuthedAdminManageRouteWithChildren =
  AuthedAdminManageRoute._addFileChildren(AuthedAdminManageRouteChildren);

interface AuthedAdminRouteRouteChildren {
  AuthedAdminManageRoute: typeof AuthedAdminManageRouteWithChildren;
  AuthedAdminApprovalsOrdersRoute: typeof AuthedAdminApprovalsOrdersRoute;
  AuthedAdminContentAnnouncementsRoute: typeof AuthedAdminContentAnnouncementsRoute;
  AuthedAdminContentFaqRoute: typeof AuthedAdminContentFaqRoute;
  AuthedAdminDistroHistoryRoute: typeof AuthedAdminDistroHistoryRoute;
  AuthedAdminDistroUploadRoute: typeof AuthedAdminDistroUploadRoute;
  AuthedAdminReportsEmailsRoute: typeof AuthedAdminReportsEmailsRoute;
  AuthedAdminReportsProductsRoute: typeof AuthedAdminReportsProductsRoute;
  AuthedAdminReportsSupportRequestsRoute: typeof AuthedAdminReportsSupportRequestsRoute;
  AuthedAdminReportsUsersRoute: typeof AuthedAdminReportsUsersRoute;
  AuthedAdminApprovalsOrdersPoNumberRoute: typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  AuthedAdminContentAnnouncementsAnnouncementIdRoute: typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  AuthedAdminContentAnnouncementsAddRoute: typeof AuthedAdminContentAnnouncementsAddRoute;
  AuthedAdminContentFaqFaqIdRoute: typeof AuthedAdminContentFaqFaqIdRoute;
  AuthedAdminContentFaqAddRoute: typeof AuthedAdminContentFaqAddRoute;
  AuthedAdminReportsEmailsEmailIdRoute: typeof AuthedAdminReportsEmailsEmailIdRoute;
}

const AuthedAdminRouteRouteChildren: AuthedAdminRouteRouteChildren = {
  AuthedAdminManageRoute: AuthedAdminManageRouteWithChildren,
  AuthedAdminApprovalsOrdersRoute: AuthedAdminApprovalsOrdersRoute,
  AuthedAdminContentAnnouncementsRoute: AuthedAdminContentAnnouncementsRoute,
  AuthedAdminContentFaqRoute: AuthedAdminContentFaqRoute,
  AuthedAdminDistroHistoryRoute: AuthedAdminDistroHistoryRoute,
  AuthedAdminDistroUploadRoute: AuthedAdminDistroUploadRoute,
  AuthedAdminReportsEmailsRoute: AuthedAdminReportsEmailsRoute,
  AuthedAdminReportsProductsRoute: AuthedAdminReportsProductsRoute,
  AuthedAdminReportsSupportRequestsRoute:
    AuthedAdminReportsSupportRequestsRoute,
  AuthedAdminReportsUsersRoute: AuthedAdminReportsUsersRoute,
  AuthedAdminApprovalsOrdersPoNumberRoute:
    AuthedAdminApprovalsOrdersPoNumberRoute,
  AuthedAdminContentAnnouncementsAnnouncementIdRoute:
    AuthedAdminContentAnnouncementsAnnouncementIdRoute,
  AuthedAdminContentAnnouncementsAddRoute:
    AuthedAdminContentAnnouncementsAddRoute,
  AuthedAdminContentFaqFaqIdRoute: AuthedAdminContentFaqFaqIdRoute,
  AuthedAdminContentFaqAddRoute: AuthedAdminContentFaqAddRoute,
  AuthedAdminReportsEmailsEmailIdRoute: AuthedAdminReportsEmailsEmailIdRoute,
};

const AuthedAdminRouteRouteWithChildren =
  AuthedAdminRouteRoute._addFileChildren(AuthedAdminRouteRouteChildren);

interface AuthedCartRouteRouteChildren {
  AuthedCartProductsRoute: typeof AuthedCartProductsRoute;
  AuthedCartQuantitiesRoute: typeof AuthedCartQuantitiesRoute;
  AuthedCartShippingRoute: typeof AuthedCartShippingRoute;
  AuthedCartStoresRoute: typeof AuthedCartStoresRoute;
  AuthedCartIndexRoute: typeof AuthedCartIndexRoute;
  AuthedCartConfirmationSalesOrderNumberRoute: typeof AuthedCartConfirmationSalesOrderNumberRoute;
}

const AuthedCartRouteRouteChildren: AuthedCartRouteRouteChildren = {
  AuthedCartProductsRoute: AuthedCartProductsRoute,
  AuthedCartQuantitiesRoute: AuthedCartQuantitiesRoute,
  AuthedCartShippingRoute: AuthedCartShippingRoute,
  AuthedCartStoresRoute: AuthedCartStoresRoute,
  AuthedCartIndexRoute: AuthedCartIndexRoute,
  AuthedCartConfirmationSalesOrderNumberRoute:
    AuthedCartConfirmationSalesOrderNumberRoute,
};

const AuthedCartRouteRouteWithChildren = AuthedCartRouteRoute._addFileChildren(
  AuthedCartRouteRouteChildren,
);

interface AuthedRouteRouteChildren {
  AuthedAdminRouteRoute: typeof AuthedAdminRouteRouteWithChildren;
  AuthedCartRouteRoute: typeof AuthedCartRouteRouteWithChildren;
  AuthedHelpRoute: typeof AuthedHelpRoute;
  AuthedProfileRoute: typeof AuthedProfileRoute;
  AuthedIndexRoute: typeof AuthedIndexRoute;
  AuthedOrdersPoNumberRoute: typeof AuthedOrdersPoNumberRoute;
  AuthedOrdersSearchRoute: typeof AuthedOrdersSearchRoute;
}

const AuthedRouteRouteChildren: AuthedRouteRouteChildren = {
  AuthedAdminRouteRoute: AuthedAdminRouteRouteWithChildren,
  AuthedCartRouteRoute: AuthedCartRouteRouteWithChildren,
  AuthedHelpRoute: AuthedHelpRoute,
  AuthedProfileRoute: AuthedProfileRoute,
  AuthedIndexRoute: AuthedIndexRoute,
  AuthedOrdersPoNumberRoute: AuthedOrdersPoNumberRoute,
  AuthedOrdersSearchRoute: AuthedOrdersSearchRoute,
};

const AuthedRouteRouteWithChildren = AuthedRouteRoute._addFileChildren(
  AuthedRouteRouteChildren,
);

export interface FileRoutesByFullPath {
  '': typeof AuthedAdminRouteRouteWithChildren;
  '/cart': typeof AuthedCartRouteRouteWithChildren;
  '/not-found': typeof errorsNotFoundRoute;
  '/first-login': typeof publicFirstLoginRoute;
  '/forgot-password': typeof publicForgotPasswordRoute;
  '/login': typeof publicLoginRoute;
  '/logout': typeof publicLogoutRoute;
  '/privacy': typeof publicPrivacyRoute;
  '/reset-password': typeof publicResetPasswordRoute;
  '/session-expired': typeof publicSessionExpiredRoute;
  '/help': typeof AuthedHelpRoute;
  '/profile': typeof AuthedProfileRoute;
  '/': typeof AuthedIndexRoute;
  '/first-login/form': typeof publicFirstLoginFormRoute;
  '/reset-password/form': typeof publicResetPasswordFormRoute;
  '/manage': typeof AuthedAdminManageRouteWithChildren;
  '/cart/products': typeof AuthedCartProductsRoute;
  '/cart/quantities': typeof AuthedCartQuantitiesRoute;
  '/cart/shipping': typeof AuthedCartShippingRoute;
  '/cart/stores': typeof AuthedCartStoresRoute;
  '/orders/$poNumber': typeof AuthedOrdersPoNumberRoute;
  '/orders/search': typeof AuthedOrdersSearchRoute;
  '/cart/': typeof AuthedCartIndexRoute;
  '/approvals/orders': typeof AuthedAdminApprovalsOrdersRoute;
  '/content/announcements': typeof AuthedAdminContentAnnouncementsRoute;
  '/content/faq': typeof AuthedAdminContentFaqRoute;
  '/distro/history': typeof AuthedAdminDistroHistoryRoute;
  '/distro/upload': typeof AuthedAdminDistroUploadRoute;
  '/manage/calendars': typeof AuthedAdminManageCalendarsRoute;
  '/manage/categories': typeof AuthedAdminManageCategoriesRoute;
  '/manage/markets': typeof AuthedAdminManageMarketsRoute;
  '/manage/plants': typeof AuthedAdminManagePlantsRoute;
  '/manage/products': typeof AuthedAdminManageProductsRoute;
  '/manage/stores': typeof AuthedAdminManageStoresRoute;
  '/manage/users': typeof AuthedAdminManageUsersRoute;
  '/reports/emails': typeof AuthedAdminReportsEmailsRoute;
  '/reports/products': typeof AuthedAdminReportsProductsRoute;
  '/reports/supportRequests': typeof AuthedAdminReportsSupportRequestsRoute;
  '/reports/users': typeof AuthedAdminReportsUsersRoute;
  '/cart/confirmation/$salesOrderNumber': typeof AuthedCartConfirmationSalesOrderNumberRoute;
  '/approvals/orders/$poNumber': typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  '/content/announcements/$announcementId': typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  '/content/announcements/add': typeof AuthedAdminContentAnnouncementsAddRoute;
  '/content/faq/$faqId': typeof AuthedAdminContentFaqFaqIdRoute;
  '/content/faq/add': typeof AuthedAdminContentFaqAddRoute;
  '/manage/calendars/$fiscalYear': typeof AuthedAdminManageCalendarsFiscalYearRoute;
  '/manage/calendars/add': typeof AuthedAdminManageCalendarsAddRoute;
  '/manage/markets/$marketId': typeof AuthedAdminManageMarketsMarketIdRoute;
  '/manage/markets/add': typeof AuthedAdminManageMarketsAddRoute;
  '/manage/plants/$warehouseLocationId': typeof AuthedAdminManagePlantsWarehouseLocationIdRoute;
  '/manage/products/$itemId': typeof AuthedAdminManageProductsItemIdRoute;
  '/manage/products/add': typeof AuthedAdminManageProductsAddRoute;
  '/manage/stores/add': typeof AuthedAdminManageStoresAddRoute;
  '/manage/users/$userName': typeof AuthedAdminManageUsersUserNameRoute;
  '/manage/users/add': typeof AuthedAdminManageUsersAddRoute;
  '/reports/emails/$emailId': typeof AuthedAdminReportsEmailsEmailIdRoute;
  '/manage/stores/$storeId/$countryCode': typeof AuthedAdminManageStoresStoreIdCountryCodeRoute;
}

export interface FileRoutesByTo {
  '': typeof AuthedAdminRouteRouteWithChildren;
  '/not-found': typeof errorsNotFoundRoute;
  '/first-login': typeof publicFirstLoginRoute;
  '/forgot-password': typeof publicForgotPasswordRoute;
  '/login': typeof publicLoginRoute;
  '/logout': typeof publicLogoutRoute;
  '/privacy': typeof publicPrivacyRoute;
  '/reset-password': typeof publicResetPasswordRoute;
  '/session-expired': typeof publicSessionExpiredRoute;
  '/help': typeof AuthedHelpRoute;
  '/profile': typeof AuthedProfileRoute;
  '/': typeof AuthedIndexRoute;
  '/first-login/form': typeof publicFirstLoginFormRoute;
  '/reset-password/form': typeof publicResetPasswordFormRoute;
  '/manage': typeof AuthedAdminManageRouteWithChildren;
  '/cart/products': typeof AuthedCartProductsRoute;
  '/cart/quantities': typeof AuthedCartQuantitiesRoute;
  '/cart/shipping': typeof AuthedCartShippingRoute;
  '/cart/stores': typeof AuthedCartStoresRoute;
  '/orders/$poNumber': typeof AuthedOrdersPoNumberRoute;
  '/orders/search': typeof AuthedOrdersSearchRoute;
  '/cart': typeof AuthedCartIndexRoute;
  '/approvals/orders': typeof AuthedAdminApprovalsOrdersRoute;
  '/content/announcements': typeof AuthedAdminContentAnnouncementsRoute;
  '/content/faq': typeof AuthedAdminContentFaqRoute;
  '/distro/history': typeof AuthedAdminDistroHistoryRoute;
  '/distro/upload': typeof AuthedAdminDistroUploadRoute;
  '/manage/calendars': typeof AuthedAdminManageCalendarsRoute;
  '/manage/categories': typeof AuthedAdminManageCategoriesRoute;
  '/manage/markets': typeof AuthedAdminManageMarketsRoute;
  '/manage/plants': typeof AuthedAdminManagePlantsRoute;
  '/manage/products': typeof AuthedAdminManageProductsRoute;
  '/manage/stores': typeof AuthedAdminManageStoresRoute;
  '/manage/users': typeof AuthedAdminManageUsersRoute;
  '/reports/emails': typeof AuthedAdminReportsEmailsRoute;
  '/reports/products': typeof AuthedAdminReportsProductsRoute;
  '/reports/supportRequests': typeof AuthedAdminReportsSupportRequestsRoute;
  '/reports/users': typeof AuthedAdminReportsUsersRoute;
  '/cart/confirmation/$salesOrderNumber': typeof AuthedCartConfirmationSalesOrderNumberRoute;
  '/approvals/orders/$poNumber': typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  '/content/announcements/$announcementId': typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  '/content/announcements/add': typeof AuthedAdminContentAnnouncementsAddRoute;
  '/content/faq/$faqId': typeof AuthedAdminContentFaqFaqIdRoute;
  '/content/faq/add': typeof AuthedAdminContentFaqAddRoute;
  '/manage/calendars/$fiscalYear': typeof AuthedAdminManageCalendarsFiscalYearRoute;
  '/manage/calendars/add': typeof AuthedAdminManageCalendarsAddRoute;
  '/manage/markets/$marketId': typeof AuthedAdminManageMarketsMarketIdRoute;
  '/manage/markets/add': typeof AuthedAdminManageMarketsAddRoute;
  '/manage/plants/$warehouseLocationId': typeof AuthedAdminManagePlantsWarehouseLocationIdRoute;
  '/manage/products/$itemId': typeof AuthedAdminManageProductsItemIdRoute;
  '/manage/products/add': typeof AuthedAdminManageProductsAddRoute;
  '/manage/stores/add': typeof AuthedAdminManageStoresAddRoute;
  '/manage/users/$userName': typeof AuthedAdminManageUsersUserNameRoute;
  '/manage/users/add': typeof AuthedAdminManageUsersAddRoute;
  '/reports/emails/$emailId': typeof AuthedAdminReportsEmailsEmailIdRoute;
  '/manage/stores/$storeId/$countryCode': typeof AuthedAdminManageStoresStoreIdCountryCodeRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/_authed': typeof AuthedRouteRouteWithChildren;
  '/_authed/_admin': typeof AuthedAdminRouteRouteWithChildren;
  '/_authed/cart': typeof AuthedCartRouteRouteWithChildren;
  '/(errors)/not-found': typeof errorsNotFoundRoute;
  '/(public)/first-login': typeof publicFirstLoginRoute;
  '/(public)/forgot-password': typeof publicForgotPasswordRoute;
  '/(public)/login': typeof publicLoginRoute;
  '/(public)/logout': typeof publicLogoutRoute;
  '/(public)/privacy': typeof publicPrivacyRoute;
  '/(public)/reset-password': typeof publicResetPasswordRoute;
  '/(public)/session-expired': typeof publicSessionExpiredRoute;
  '/_authed/help': typeof AuthedHelpRoute;
  '/_authed/profile': typeof AuthedProfileRoute;
  '/_authed/': typeof AuthedIndexRoute;
  '/(public)/first-login_/form': typeof publicFirstLoginFormRoute;
  '/(public)/reset-password_/form': typeof publicResetPasswordFormRoute;
  '/_authed/_admin/manage': typeof AuthedAdminManageRouteWithChildren;
  '/_authed/cart/products': typeof AuthedCartProductsRoute;
  '/_authed/cart/quantities': typeof AuthedCartQuantitiesRoute;
  '/_authed/cart/shipping': typeof AuthedCartShippingRoute;
  '/_authed/cart/stores': typeof AuthedCartStoresRoute;
  '/_authed/orders/$poNumber': typeof AuthedOrdersPoNumberRoute;
  '/_authed/orders/search': typeof AuthedOrdersSearchRoute;
  '/_authed/cart/': typeof AuthedCartIndexRoute;
  '/_authed/_admin/approvals/orders': typeof AuthedAdminApprovalsOrdersRoute;
  '/_authed/_admin/content/announcements': typeof AuthedAdminContentAnnouncementsRoute;
  '/_authed/_admin/content/faq': typeof AuthedAdminContentFaqRoute;
  '/_authed/_admin/distro/history': typeof AuthedAdminDistroHistoryRoute;
  '/_authed/_admin/distro/upload': typeof AuthedAdminDistroUploadRoute;
  '/_authed/_admin/manage/calendars': typeof AuthedAdminManageCalendarsRoute;
  '/_authed/_admin/manage/categories': typeof AuthedAdminManageCategoriesRoute;
  '/_authed/_admin/manage/markets': typeof AuthedAdminManageMarketsRoute;
  '/_authed/_admin/manage/plants': typeof AuthedAdminManagePlantsRoute;
  '/_authed/_admin/manage/products': typeof AuthedAdminManageProductsRoute;
  '/_authed/_admin/manage/stores': typeof AuthedAdminManageStoresRoute;
  '/_authed/_admin/manage/users': typeof AuthedAdminManageUsersRoute;
  '/_authed/_admin/reports/emails': typeof AuthedAdminReportsEmailsRoute;
  '/_authed/_admin/reports/products': typeof AuthedAdminReportsProductsRoute;
  '/_authed/_admin/reports/supportRequests': typeof AuthedAdminReportsSupportRequestsRoute;
  '/_authed/_admin/reports/users': typeof AuthedAdminReportsUsersRoute;
  '/_authed/cart/confirmation/$salesOrderNumber': typeof AuthedCartConfirmationSalesOrderNumberRoute;
  '/_authed/_admin/approvals/orders_/$poNumber': typeof AuthedAdminApprovalsOrdersPoNumberRoute;
  '/_authed/_admin/content/announcements_/$announcementId': typeof AuthedAdminContentAnnouncementsAnnouncementIdRoute;
  '/_authed/_admin/content/announcements_/add': typeof AuthedAdminContentAnnouncementsAddRoute;
  '/_authed/_admin/content/faq_/$faqId': typeof AuthedAdminContentFaqFaqIdRoute;
  '/_authed/_admin/content/faq_/add': typeof AuthedAdminContentFaqAddRoute;
  '/_authed/_admin/manage/calendars_/$fiscalYear': typeof AuthedAdminManageCalendarsFiscalYearRoute;
  '/_authed/_admin/manage/calendars_/add': typeof AuthedAdminManageCalendarsAddRoute;
  '/_authed/_admin/manage/markets_/$marketId': typeof AuthedAdminManageMarketsMarketIdRoute;
  '/_authed/_admin/manage/markets_/add': typeof AuthedAdminManageMarketsAddRoute;
  '/_authed/_admin/manage/plants_/$warehouseLocationId': typeof AuthedAdminManagePlantsWarehouseLocationIdRoute;
  '/_authed/_admin/manage/products_/$itemId': typeof AuthedAdminManageProductsItemIdRoute;
  '/_authed/_admin/manage/products_/add': typeof AuthedAdminManageProductsAddRoute;
  '/_authed/_admin/manage/stores_/add': typeof AuthedAdminManageStoresAddRoute;
  '/_authed/_admin/manage/users_/$userName': typeof AuthedAdminManageUsersUserNameRoute;
  '/_authed/_admin/manage/users_/add': typeof AuthedAdminManageUsersAddRoute;
  '/_authed/_admin/reports/emails_/$emailId': typeof AuthedAdminReportsEmailsEmailIdRoute;
  '/_authed/_admin/manage/stores_/$storeId_/$countryCode': typeof AuthedAdminManageStoresStoreIdCountryCodeRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ''
    | '/cart'
    | '/not-found'
    | '/first-login'
    | '/forgot-password'
    | '/login'
    | '/logout'
    | '/privacy'
    | '/reset-password'
    | '/session-expired'
    | '/help'
    | '/profile'
    | '/'
    | '/first-login/form'
    | '/reset-password/form'
    | '/manage'
    | '/cart/products'
    | '/cart/quantities'
    | '/cart/shipping'
    | '/cart/stores'
    | '/orders/$poNumber'
    | '/orders/search'
    | '/cart/'
    | '/approvals/orders'
    | '/content/announcements'
    | '/content/faq'
    | '/distro/history'
    | '/distro/upload'
    | '/manage/calendars'
    | '/manage/categories'
    | '/manage/markets'
    | '/manage/plants'
    | '/manage/products'
    | '/manage/stores'
    | '/manage/users'
    | '/reports/emails'
    | '/reports/products'
    | '/reports/supportRequests'
    | '/reports/users'
    | '/cart/confirmation/$salesOrderNumber'
    | '/approvals/orders/$poNumber'
    | '/content/announcements/$announcementId'
    | '/content/announcements/add'
    | '/content/faq/$faqId'
    | '/content/faq/add'
    | '/manage/calendars/$fiscalYear'
    | '/manage/calendars/add'
    | '/manage/markets/$marketId'
    | '/manage/markets/add'
    | '/manage/plants/$warehouseLocationId'
    | '/manage/products/$itemId'
    | '/manage/products/add'
    | '/manage/stores/add'
    | '/manage/users/$userName'
    | '/manage/users/add'
    | '/reports/emails/$emailId'
    | '/manage/stores/$storeId/$countryCode';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | ''
    | '/not-found'
    | '/first-login'
    | '/forgot-password'
    | '/login'
    | '/logout'
    | '/privacy'
    | '/reset-password'
    | '/session-expired'
    | '/help'
    | '/profile'
    | '/'
    | '/first-login/form'
    | '/reset-password/form'
    | '/manage'
    | '/cart/products'
    | '/cart/quantities'
    | '/cart/shipping'
    | '/cart/stores'
    | '/orders/$poNumber'
    | '/orders/search'
    | '/cart'
    | '/approvals/orders'
    | '/content/announcements'
    | '/content/faq'
    | '/distro/history'
    | '/distro/upload'
    | '/manage/calendars'
    | '/manage/categories'
    | '/manage/markets'
    | '/manage/plants'
    | '/manage/products'
    | '/manage/stores'
    | '/manage/users'
    | '/reports/emails'
    | '/reports/products'
    | '/reports/supportRequests'
    | '/reports/users'
    | '/cart/confirmation/$salesOrderNumber'
    | '/approvals/orders/$poNumber'
    | '/content/announcements/$announcementId'
    | '/content/announcements/add'
    | '/content/faq/$faqId'
    | '/content/faq/add'
    | '/manage/calendars/$fiscalYear'
    | '/manage/calendars/add'
    | '/manage/markets/$marketId'
    | '/manage/markets/add'
    | '/manage/plants/$warehouseLocationId'
    | '/manage/products/$itemId'
    | '/manage/products/add'
    | '/manage/stores/add'
    | '/manage/users/$userName'
    | '/manage/users/add'
    | '/reports/emails/$emailId'
    | '/manage/stores/$storeId/$countryCode';
  id:
    | '__root__'
    | '/_authed'
    | '/_authed/_admin'
    | '/_authed/cart'
    | '/(errors)/not-found'
    | '/(public)/first-login'
    | '/(public)/forgot-password'
    | '/(public)/login'
    | '/(public)/logout'
    | '/(public)/privacy'
    | '/(public)/reset-password'
    | '/(public)/session-expired'
    | '/_authed/help'
    | '/_authed/profile'
    | '/_authed/'
    | '/(public)/first-login_/form'
    | '/(public)/reset-password_/form'
    | '/_authed/_admin/manage'
    | '/_authed/cart/products'
    | '/_authed/cart/quantities'
    | '/_authed/cart/shipping'
    | '/_authed/cart/stores'
    | '/_authed/orders/$poNumber'
    | '/_authed/orders/search'
    | '/_authed/cart/'
    | '/_authed/_admin/approvals/orders'
    | '/_authed/_admin/content/announcements'
    | '/_authed/_admin/content/faq'
    | '/_authed/_admin/distro/history'
    | '/_authed/_admin/distro/upload'
    | '/_authed/_admin/manage/calendars'
    | '/_authed/_admin/manage/categories'
    | '/_authed/_admin/manage/markets'
    | '/_authed/_admin/manage/plants'
    | '/_authed/_admin/manage/products'
    | '/_authed/_admin/manage/stores'
    | '/_authed/_admin/manage/users'
    | '/_authed/_admin/reports/emails'
    | '/_authed/_admin/reports/products'
    | '/_authed/_admin/reports/supportRequests'
    | '/_authed/_admin/reports/users'
    | '/_authed/cart/confirmation/$salesOrderNumber'
    | '/_authed/_admin/approvals/orders_/$poNumber'
    | '/_authed/_admin/content/announcements_/$announcementId'
    | '/_authed/_admin/content/announcements_/add'
    | '/_authed/_admin/content/faq_/$faqId'
    | '/_authed/_admin/content/faq_/add'
    | '/_authed/_admin/manage/calendars_/$fiscalYear'
    | '/_authed/_admin/manage/calendars_/add'
    | '/_authed/_admin/manage/markets_/$marketId'
    | '/_authed/_admin/manage/markets_/add'
    | '/_authed/_admin/manage/plants_/$warehouseLocationId'
    | '/_authed/_admin/manage/products_/$itemId'
    | '/_authed/_admin/manage/products_/add'
    | '/_authed/_admin/manage/stores_/add'
    | '/_authed/_admin/manage/users_/$userName'
    | '/_authed/_admin/manage/users_/add'
    | '/_authed/_admin/reports/emails_/$emailId'
    | '/_authed/_admin/manage/stores_/$storeId_/$countryCode';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  AuthedRouteRoute: typeof AuthedRouteRouteWithChildren;
  errorsNotFoundRoute: typeof errorsNotFoundRoute;
  publicFirstLoginRoute: typeof publicFirstLoginRoute;
  publicForgotPasswordRoute: typeof publicForgotPasswordRoute;
  publicLoginRoute: typeof publicLoginRoute;
  publicLogoutRoute: typeof publicLogoutRoute;
  publicPrivacyRoute: typeof publicPrivacyRoute;
  publicResetPasswordRoute: typeof publicResetPasswordRoute;
  publicSessionExpiredRoute: typeof publicSessionExpiredRoute;
  publicFirstLoginFormRoute: typeof publicFirstLoginFormRoute;
  publicResetPasswordFormRoute: typeof publicResetPasswordFormRoute;
}

const rootRouteChildren: RootRouteChildren = {
  AuthedRouteRoute: AuthedRouteRouteWithChildren,
  errorsNotFoundRoute: errorsNotFoundRoute,
  publicFirstLoginRoute: publicFirstLoginRoute,
  publicForgotPasswordRoute: publicForgotPasswordRoute,
  publicLoginRoute: publicLoginRoute,
  publicLogoutRoute: publicLogoutRoute,
  publicPrivacyRoute: publicPrivacyRoute,
  publicResetPasswordRoute: publicResetPasswordRoute,
  publicSessionExpiredRoute: publicSessionExpiredRoute,
  publicFirstLoginFormRoute: publicFirstLoginFormRoute,
  publicResetPasswordFormRoute: publicResetPasswordFormRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authed",
        "/(errors)/not-found",
        "/(public)/first-login",
        "/(public)/forgot-password",
        "/(public)/login",
        "/(public)/logout",
        "/(public)/privacy",
        "/(public)/reset-password",
        "/(public)/session-expired",
        "/(public)/first-login_/form",
        "/(public)/reset-password_/form"
      ]
    },
    "/_authed": {
      "filePath": "_authed/route.tsx",
      "children": [
        "/_authed/_admin",
        "/_authed/cart",
        "/_authed/help",
        "/_authed/profile",
        "/_authed/",
        "/_authed/orders/$poNumber",
        "/_authed/orders/search"
      ]
    },
    "/_authed/_admin": {
      "filePath": "_authed/_admin/route.tsx",
      "parent": "/_authed",
      "children": [
        "/_authed/_admin/manage",
        "/_authed/_admin/approvals/orders",
        "/_authed/_admin/content/announcements",
        "/_authed/_admin/content/faq",
        "/_authed/_admin/distro/history",
        "/_authed/_admin/distro/upload",
        "/_authed/_admin/reports/emails",
        "/_authed/_admin/reports/products",
        "/_authed/_admin/reports/supportRequests",
        "/_authed/_admin/reports/users",
        "/_authed/_admin/approvals/orders_/$poNumber",
        "/_authed/_admin/content/announcements_/$announcementId",
        "/_authed/_admin/content/announcements_/add",
        "/_authed/_admin/content/faq_/$faqId",
        "/_authed/_admin/content/faq_/add",
        "/_authed/_admin/reports/emails_/$emailId"
      ]
    },
    "/_authed/cart": {
      "filePath": "_authed/cart/route.tsx",
      "parent": "/_authed",
      "children": [
        "/_authed/cart/products",
        "/_authed/cart/quantities",
        "/_authed/cart/shipping",
        "/_authed/cart/stores",
        "/_authed/cart/",
        "/_authed/cart/confirmation/$salesOrderNumber"
      ]
    },
    "/(errors)/not-found": {
      "filePath": "(errors)/not-found.tsx"
    },
    "/(public)/first-login": {
      "filePath": "(public)/first-login.tsx"
    },
    "/(public)/forgot-password": {
      "filePath": "(public)/forgot-password.tsx"
    },
    "/(public)/login": {
      "filePath": "(public)/login.tsx"
    },
    "/(public)/logout": {
      "filePath": "(public)/logout.tsx"
    },
    "/(public)/privacy": {
      "filePath": "(public)/privacy.tsx"
    },
    "/(public)/reset-password": {
      "filePath": "(public)/reset-password.tsx"
    },
    "/(public)/session-expired": {
      "filePath": "(public)/session-expired.tsx"
    },
    "/_authed/help": {
      "filePath": "_authed/help.tsx",
      "parent": "/_authed"
    },
    "/_authed/profile": {
      "filePath": "_authed/profile.tsx",
      "parent": "/_authed"
    },
    "/_authed/": {
      "filePath": "_authed/index.tsx",
      "parent": "/_authed"
    },
    "/(public)/first-login_/form": {
      "filePath": "(public)/first-login_.form.tsx"
    },
    "/(public)/reset-password_/form": {
      "filePath": "(public)/reset-password_.form.tsx"
    },
    "/_authed/_admin/manage": {
      "filePath": "_authed/_admin/manage.tsx",
      "parent": "/_authed/_admin",
      "children": [
        "/_authed/_admin/manage/calendars",
        "/_authed/_admin/manage/categories",
        "/_authed/_admin/manage/markets",
        "/_authed/_admin/manage/plants",
        "/_authed/_admin/manage/products",
        "/_authed/_admin/manage/stores",
        "/_authed/_admin/manage/users",
        "/_authed/_admin/manage/calendars_/$fiscalYear",
        "/_authed/_admin/manage/calendars_/add",
        "/_authed/_admin/manage/markets_/$marketId",
        "/_authed/_admin/manage/markets_/add",
        "/_authed/_admin/manage/plants_/$warehouseLocationId",
        "/_authed/_admin/manage/products_/$itemId",
        "/_authed/_admin/manage/products_/add",
        "/_authed/_admin/manage/stores_/add",
        "/_authed/_admin/manage/users_/$userName",
        "/_authed/_admin/manage/users_/add",
        "/_authed/_admin/manage/stores_/$storeId_/$countryCode"
      ]
    },
    "/_authed/cart/products": {
      "filePath": "_authed/cart/products.tsx",
      "parent": "/_authed/cart"
    },
    "/_authed/cart/quantities": {
      "filePath": "_authed/cart/quantities.tsx",
      "parent": "/_authed/cart"
    },
    "/_authed/cart/shipping": {
      "filePath": "_authed/cart/shipping.tsx",
      "parent": "/_authed/cart"
    },
    "/_authed/cart/stores": {
      "filePath": "_authed/cart/stores.tsx",
      "parent": "/_authed/cart"
    },
    "/_authed/orders/$poNumber": {
      "filePath": "_authed/orders.$poNumber.tsx",
      "parent": "/_authed"
    },
    "/_authed/orders/search": {
      "filePath": "_authed/orders.search.tsx",
      "parent": "/_authed"
    },
    "/_authed/cart/": {
      "filePath": "_authed/cart/index.tsx",
      "parent": "/_authed/cart"
    },
    "/_authed/_admin/approvals/orders": {
      "filePath": "_authed/_admin/approvals.orders.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/announcements": {
      "filePath": "_authed/_admin/content.announcements.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/faq": {
      "filePath": "_authed/_admin/content.faq.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/distro/history": {
      "filePath": "_authed/_admin/distro.history.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/distro/upload": {
      "filePath": "_authed/_admin/distro.upload.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/manage/calendars": {
      "filePath": "_authed/_admin/manage.calendars.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/categories": {
      "filePath": "_authed/_admin/manage.categories.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/markets": {
      "filePath": "_authed/_admin/manage.markets.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/plants": {
      "filePath": "_authed/_admin/manage.plants.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/products": {
      "filePath": "_authed/_admin/manage.products.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/stores": {
      "filePath": "_authed/_admin/manage.stores.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/users": {
      "filePath": "_authed/_admin/manage.users.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/reports/emails": {
      "filePath": "_authed/_admin/reports/emails.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/products": {
      "filePath": "_authed/_admin/reports/products.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/supportRequests": {
      "filePath": "_authed/_admin/reports/supportRequests.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/reports/users": {
      "filePath": "_authed/_admin/reports/users.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/cart/confirmation/$salesOrderNumber": {
      "filePath": "_authed/cart/confirmation.$salesOrderNumber.tsx",
      "parent": "/_authed/cart"
    },
    "/_authed/_admin/approvals/orders_/$poNumber": {
      "filePath": "_authed/_admin/approvals.orders_.$poNumber.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/announcements_/$announcementId": {
      "filePath": "_authed/_admin/content.announcements_.$announcementId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/announcements_/add": {
      "filePath": "_authed/_admin/content.announcements_.add.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/faq_/$faqId": {
      "filePath": "_authed/_admin/content.faq_.$faqId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/content/faq_/add": {
      "filePath": "_authed/_admin/content.faq_.add.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/manage/calendars_/$fiscalYear": {
      "filePath": "_authed/_admin/manage.calendars_.$fiscalYear.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/calendars_/add": {
      "filePath": "_authed/_admin/manage.calendars_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/markets_/$marketId": {
      "filePath": "_authed/_admin/manage.markets_.$marketId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/markets_/add": {
      "filePath": "_authed/_admin/manage.markets_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/plants_/$warehouseLocationId": {
      "filePath": "_authed/_admin/manage.plants_.$warehouseLocationId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/products_/$itemId": {
      "filePath": "_authed/_admin/manage.products_.$itemId.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/products_/add": {
      "filePath": "_authed/_admin/manage.products_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/stores_/add": {
      "filePath": "_authed/_admin/manage.stores_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/users_/$userName": {
      "filePath": "_authed/_admin/manage.users_.$userName.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/manage/users_/add": {
      "filePath": "_authed/_admin/manage.users_.add.tsx",
      "parent": "/_authed/_admin/manage"
    },
    "/_authed/_admin/reports/emails_/$emailId": {
      "filePath": "_authed/_admin/reports/emails_.$emailId.tsx",
      "parent": "/_authed/_admin"
    },
    "/_authed/_admin/manage/stores_/$storeId_/$countryCode": {
      "filePath": "_authed/_admin/manage.stores_.$storeId_.$countryCode.tsx",
      "parent": "/_authed/_admin/manage"
    }
  }
}
ROUTE_MANIFEST_END */
