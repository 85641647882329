import { z } from 'zod';

import {
	excelProductKeySchema,
	manzanitaProductSchema,
} from '@apple/features/product/models/management';
import { createExcelErrorSchema, createExcelResultSchema } from '@apple/utils/files';
import { dateTimeSchema, emptyOrNullableDateTimeSchema } from '@apple/utils/globalization';
import type { Product } from '@apple/features/product/models/management';
import type { ExcelError, ExcelResult } from '@apple/utils/files';

import { marketAssociationSchema, storeAssociationSchema } from '../models/associations';
import { storeEventProductConfigurationSchema } from '../models/store-events';
import type { MarketAssociation, StoreAssociation } from '../models/associations';
import type { StoreEventProductConfiguration } from '../models/store-events';

/**
 * @see Manzanita.Data.Manzanita.ManzanitaProduct
 * @see AppleRetail.Catalog.AppleRetailProduct
 */
export interface AppleRetailProduct extends Product {
	customerGroupAssociations: MarketAssociation[];
	customerAssociations: StoreAssociation[];
	administratorOnly: boolean;
	quantityLimits: QuantityLimit[];
	storeEventConfiguration: StoreEventProductConfiguration | null;
	priceOverrides: PriceOverride[];
}

/** AppleRetail.Data.AppleRetail.QuantityLimit */
export interface QuantityLimit {
	itemId: string;
	accessTypeId: number;
	accessConfig: string | null;
	startDate: Date | '' | null;
	endDate: Date | '' | null;
	maxQuantity: number;
	updatedDate?: Date | null;
}

export interface PriceOverride {
	warehouseLocationId: number;
	sku: string;
	priceOverride: number | null;
	updatedDate?: Date | null;
}

export const quantityLimitSchema = z.object({
	itemId: z.string(),
	accessTypeId: z.number(),
	accessConfig: z.string().nullable(),
	startDate: emptyOrNullableDateTimeSchema,
	endDate: emptyOrNullableDateTimeSchema,
	maxQuantity: z.number(),
	updatedDate: dateTimeSchema.nullable().optional(),
}) as z.ZodType<QuantityLimit>;

export const priceOverrideSchema = z.object({
	warehouseLocationId: z.number(),
	sku: z.string(),
	priceOverride: z.number().nullable(),
	updatedDate: dateTimeSchema.nullable().optional(),
}) as z.ZodType<PriceOverride>;

export const appleRetailProductSchema = manzanitaProductSchema.and(
	z.object({
		customerGroupAssociations: z.array(marketAssociationSchema),
		customerAssociations: z.array(storeAssociationSchema),
		quantityLimits: z.array(quantityLimitSchema),
		storeEventConfiguration: storeEventProductConfigurationSchema.nullable(),
		administratorOnly: z.boolean(),
		priceOverrides: z.array(priceOverrideSchema),
	}),
);

export type ProductExcelResult = ExcelResult<AppleRetailProduct, 'itemId', ProductExcelResultError>;
export type ProductExcelResultError = ExcelError<AppleRetailProduct, 'itemId'>;

export const productExcelErrorSchema = createExcelErrorSchema(
	excelProductKeySchema,
) satisfies z.ZodType<ProductExcelResultError>;

export const productExcelResultSchema = createExcelResultSchema(
	appleRetailProductSchema,
	productExcelErrorSchema,
	excelProductKeySchema.shape.itemId,
) satisfies z.ZodType<ProductExcelResult>;
