import { z } from 'zod';

/**
 * @see AppleRetail.StoreEvents.DataModels.StoreEventType
 */
export type StoreEventType = 'NewStore' | 'Upgrade' | 'Relocation' | 'Eagle';

/**
 * @see AppleRetail.StoreEvents.DataModels.StoreEventType
 */
export const storeEventTypeSchema = z.enum([
	'NewStore',
	'Upgrade',
	'Relocation',
	'Eagle',
]) as z.ZodType<StoreEventType>;
