import { z, ZodIssueCode } from 'zod';

import { storeEventTypeSchema } from '@apple/features/store-events/models';
import { storeEventCategorySizeSchema } from '@apple/features/store-events/models/categories';
import { countryCodeSchema, dateTimeSchema } from '@apple/utils/globalization/models';
import type { StoreEventType } from '@apple/features/store-events/models';
import type { StoreEventCategorySize } from '@apple/features/store-events/models/categories';
import type { CountryCode } from '@apple/utils/globalization/models';

export type StoreEventRuleType = 'global' | 'plant' | 'country' | 'market';

export interface StoreEventProductRule {
	itemId: string;
	storeEventCategoryId: string;
	categorySize: StoreEventCategorySize | null;
	eventType: string;
	defaultQuantity: number;
	updateddate?: string;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductGlobalAssociation
 */
export interface GlobalStoreEventRule extends StoreEventProductRule {}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductPlantAssociation
 */
export interface PlantStoreEventRule extends StoreEventProductRule {
	warehouseLocationId: number;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCountryAssociation
 */
export interface CountryStoreEventRule extends StoreEventProductRule {
	countryCode: CountryCode;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCustomerGroupAssociation
 */
export interface MarketStoreEventRule extends StoreEventProductRule {
	customerGroupId: number;
}

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventProductConfiguration
 */
export interface StoreEventProductConfiguration {
	itemId: string;
	isEnabled: boolean;
	updatedDate?: Date | null;
	globalAssociations: GlobalStoreEventRule[];
	plantAssociations: PlantStoreEventRule[];
	countryAssociations: CountryStoreEventRule[];
	customerGroupAssociations: MarketStoreEventRule[];
}

/**
 * Used in the form to create store event rules for a product
 */
export interface StoreEventRule {
	eventType: StoreEventType;
	category: string;
	categorySizeRequired: boolean;
	categorySize: StoreEventCategorySize | null;
	accessType: StoreEventRuleType;
	configuration: string | null;
	quantity: number;
}

export const storeEventRuleTypeSchema = z.enum([
	'global',
	'plant',
	'country',
	'market',
]) satisfies z.ZodType<StoreEventRuleType>;

const storeEventProductRuleSchema = z.object({
	itemId: z.string(),
	storeEventCategoryId: z.string(),
	categorySize: storeEventCategorySizeSchema.nullable(),
	eventType: z.string(),
	defaultQuantity: z.number(),
	updatedDate: dateTimeSchema.nullable().optional(),
}) satisfies z.ZodType<StoreEventProductRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductGlobalAssociation
 */
export const globalStoreEventRuleSchema =
	storeEventProductRuleSchema satisfies z.ZodType<GlobalStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductPlantAssociation
 */
export const plantStoreEventRuleSchema = storeEventProductRuleSchema.extend({
	warehouseLocationId: z.number(),
}) satisfies z.ZodType<PlantStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCountryAssociation
 */
export const countryStoreEventRuleSchema = storeEventProductRuleSchema.extend({
	countryCode: countryCodeSchema,
}) satisfies z.ZodType<CountryStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventCategoryProductCustomerGroupAssociation
 */
export const marketStoreEventRuleSchema = storeEventProductRuleSchema.extend({
	customerGroupId: z.number(),
}) satisfies z.ZodType<MarketStoreEventRule>;

/**
 * @see AppleRetail.Data.StoreEvents.StoreEventProductConfiguration
 */
export const storeEventProductConfigurationSchema = z.object({
	itemId: z.string(),
	isEnabled: z.boolean(),
	updatedDate: dateTimeSchema.nullable().optional(),
	globalAssociations: z.array(globalStoreEventRuleSchema),
	plantAssociations: z.array(plantStoreEventRuleSchema),
	countryAssociations: z.array(countryStoreEventRuleSchema),
	customerGroupAssociations: z.array(marketStoreEventRuleSchema),
}) as z.ZodType<StoreEventProductConfiguration>;

/**
 * Used in the form to create store event rules for a product
 */
export const storeEventRuleSchema = (categorySizeMessage: string, configurationMessage: string) =>
	z
		.object({
			eventType: storeEventTypeSchema,
			category: z.string().min(1),
			categorySizeRequired: z.boolean(),
			categorySize: storeEventCategorySizeSchema.nullable(),
			accessType: storeEventRuleTypeSchema,
			configuration: z.string().nullable(),
			quantity: z.coerce.number().min(1),
		})
		.superRefine((data, ctx) => {
			if (data.categorySizeRequired && (data.categorySize ?? '') === '') {
				ctx.addIssue({
					code: ZodIssueCode.custom,
					message: categorySizeMessage,
					path: ['categorySize'],
				});
			}
			if (data.accessType !== 'global' && (data.configuration ?? '') === '') {
				ctx.addIssue({
					code: ZodIssueCode.custom,
					message: configurationMessage,
					path: ['configuration'],
				});
			}
		}) satisfies z.ZodType<StoreEventRule>;
