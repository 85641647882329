import { queryOptions } from '@tanstack/react-query';

import { get, getNewNewFiscalYear } from '@apple/features/calendar/api';

export const calendarKeys = {
	all: ['fiscal-calendar'],
	fiscalYear: (fiscalYear: number) => ['fiscal-calendar', fiscalYear],
	add: ['fiscal-calendar', 'add'],
} as const;

export const calendarQueryOptions = {
	newFiscalYear: queryOptions({
		queryKey: calendarKeys.add,
		queryFn: () => getNewNewFiscalYear(),
		staleTime: Infinity,
		throwOnError: true,
	}),
	fiscalYear: (fiscalYear: number) =>
		queryOptions({
			queryKey: calendarKeys.fiscalYear(fiscalYear),
			queryFn: () => get(fiscalYear),
			staleTime: Infinity,
			throwOnError: true,
		}),
};
