import { lazy } from 'react';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { createRootRouteWithContext, useRouterState } from '@tanstack/react-router';
import type { QueryClient } from '@tanstack/react-query';

import { useAppleRetailNavMenu } from '@apple/retail/hooks/useAppleRetailNavMenu';
import { AppleLayout } from '@apple/ui/layouts/AppleLayout';
import { MaintenancePage } from '@apple/ui/shell/pages/MaintenancePage';
import { getEnv } from '@apple/utils/config/env';
import type { AuthContext } from '@apple/features/auth-retail/contexts/auth-context';
import type { CurrentUserLanguage } from '@apple/features/user/hooks/useCurrentUserLanguage';

export type RootRouteContext = {
	queryClient: QueryClient;
	auth: AuthContext;
	lang: CurrentUserLanguage;
};

export const Route = createRootRouteWithContext<RootRouteContext>()({
	wrapInSuspense: true,
	component: RootRoute,
});

const env = getEnv();

function RootRoute() {
	const { auth } = Route.useRouteContext();
	const [devtoolsVisible, setDevtoolsVisible] = useLocalStorage({
		key: 'devtools-visible',
		defaultValue: env.DEV,
		getInitialValueInEffect: false,
	});
	useHotkeys([['alt+x', () => setDevtoolsVisible(x => !x)]]);

	return (
		<>
			{auth.error ? <MaintenancePage /> : <Layout />}
			{devtoolsVisible && (
				<>
					<TanStackRouterDevtools />
					<ReactQueryDevtools />
				</>
			)}
		</>
	);
}

function Layout() {
	const { menuItems } = useAppleRetailNavMenu();
	const isLoading = useRouterState({ select: s => s.status === 'pending' });
	return <AppleLayout menuItems={menuItems} loading={isLoading} />;
}

const TanStackRouterDevtools = import.meta.env.PROD
	? () => null // Render nothing in production
	: lazy(() =>
			// Lazy load in development
			import('@tanstack/router-devtools').then(res => ({
				default: res.TanStackRouterDevtools,
			})),
		);

const ReactQueryDevtools = import.meta.env.PROD
	? () => null // Render nothing in production
	: lazy(() =>
			// Lazy load in development
			import('@tanstack/react-query-devtools').then(res => ({
				default: res.ReactQueryDevtools,
			})),
		);
