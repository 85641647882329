import axios from 'axios';

import { AccessType, userModelSchema } from '@apple/features/user/models/management';
import type {
	UserCountryAssociation,
	UserPlantAssociation,
} from '@apple/features/user/models/associations';
import type { UserModel } from '@apple/features/user/models/management';
import type { CountryCode } from '@apple/utils/globalization';

import { userFormDataSchema, userManagementOptionsSchema } from '../models/management';
import type {
	UserFormData,
	UserManagementOptions,
	UserMarketAssociation,
	UserStoreAssociation,
} from '../models/management';

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function getUser(username: string, signal?: AbortSignal): Promise<UserFormData> {
	const { data } = await axios.get<unknown>(
		`/api/management/users/${encodeURIComponent(username)}/`,
		{ signal },
	);
	const result = userModelSchema.parse(data);
	return getUserFormData(result);
}

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function saveUser(user: UserFormData): Promise<void> {
	const model = getUserModel(user);
	await axios.post('/api/management/users/save', model);
}

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function addUser(user: UserFormData): Promise<void> {
	const model = getUserModel(user);
	await axios.post('/api/management/users/add', model, { params: { isSpa: true } });
}

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function unlockUser(username: string): Promise<void> {
	await axios.post(`/api/management/users/${encodeURIComponent(username)}/unlock`);
}

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function deleteUser(username: string): Promise<void> {
	await axios.delete('/api/management/users', { params: { username } });
}

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function deleteUsers(usernames: string[], auditComment?: string): Promise<void> {
	if (usernames.length === 0) {
		return;
	}

	await axios.post('/api/management/users/delete-multiple', usernames, {
		headers: auditComment ? { ChangeComment: auditComment } : undefined,
	});
}

/** @see AppleRetail.Web.Features.Profiles.UserManagementController */
export async function getUserManagementOptions(
	signal?: AbortSignal,
): Promise<UserManagementOptions> {
	const { data } = await axios.get<unknown>(`/api/management/users/data`, { signal });
	return userManagementOptionsSchema.parse(data);
}

/** Gets the user form data from the user model expected by the management API.
 * Some properties are named differently in the server validation model and are mapped.
 * Associations are filtered by type into separate properties.
 */
export function getUserFormData(user: UserModel): UserFormData {
	return userFormDataSchema.parse({
		...user,
		emailAddress: user.email,
		defaultCountry: user.countryCode ?? '',
		language: user.cultureCode ?? '',
		userRole: user.userRole ?? '',
		allAssociation: user.userAssociations.some(x => x.accessType === AccessType.All)
			? { userId: user.id }
			: null,
		plantAssociations: user.userAssociations
			.filter(x => x.accessType === AccessType.Plant)
			.map<UserPlantAssociation>(x => ({
				plantId: Number(x.primaryAssociation),
				userId: user.id,
			})),
		countryAssociations: user.userAssociations
			.filter(x => x.accessType === AccessType.Country)
			.map<UserCountryAssociation>(x => ({
				countryCode: x.primaryAssociation as CountryCode,
				userId: user.id,
			})),
		marketAssociations: user.userAssociations
			.filter(x => x.accessType === AccessType.CustomerGroup)
			.map<UserMarketAssociation>(x => ({
				customerGroupId: Number(x.primaryAssociation),
				userId: user.id,
			})),
		storeAssociations: user.userAssociations
			.filter(x => x.accessType === AccessType.Customer)
			.map<UserStoreAssociation>(x => ({
				customerId: Number(x.primaryAssociation),
				userId: user.id,
			})),
	});
}

/** Gets the user model expected by the management API from user form data.
 * Some properties are named differently in the server validation model and are mapped.
 * Associations are stored in a single array of user associations.
 */
export function getUserModel(user: UserFormData): UserModel {
	return userModelSchema.parse({
		...user,
		email: user.emailAddress,
		countryCode: user.defaultCountry,
		cultureCode: user.language,
		userAssociations: [
			...(user.allAssociation
				? [{ accessType: AccessType.All, primaryAssociation: '0' }]
				: []),
			...user.plantAssociations.map((x: UserPlantAssociation) => ({
				accessType: AccessType.Plant,
				primaryAssociation: x.plantId.toString(),
			})),
			...user.countryAssociations.map((x: UserCountryAssociation) => ({
				accessType: AccessType.Country,
				primaryAssociation: x.countryCode,
			})),
			...user.marketAssociations.map((x: UserMarketAssociation) => ({
				accessType: AccessType.CustomerGroup,
				primaryAssociation: x.customerGroupId.toString(),
			})),
			...user.storeAssociations.map((x: UserStoreAssociation) => ({
				accessType: AccessType.Customer,
				primaryAssociation: x.customerId.toString(),
			})),
		],
	});
}
