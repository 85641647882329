import { z } from 'zod';

import { localDateTimeSchema } from '@apple/utils/globalization';

/** @see Manzanita.Web.Areas.Admin.Models.UserModel */
export type UserModel = z.infer<typeof userModelSchema>;

/** @see Manzanita.Web.Areas.Admin.Models.AccessTypeViewModel */
export type AccessTypeModel = z.infer<typeof accessTypeModelSchema>;

export type UserRole = string;

/** @see Manzanita.Web.Areas.Admin.Models.AccessType */
export enum AccessType {
	CustomerGroup = 1,
	Customer = 2,
	Plant = 3,
	Country = 4,
	CustomerGroupPlant = 5,
	CustomerGroupCountry = 6,
	All = 7,
}

/** @see Manzanita.Web.Areas.Admin.Models.AccessType */
export const accessTypeSchema = z.nativeEnum(AccessType);

/** @see Manzanita.Web.Areas.Admin.Models.AccessTypeViewModel */
export const accessTypeModelSchema = z.object({
	accessType: accessTypeSchema,
	primaryAssociation: z.ostring().nullable(),
	secondaryAssociation: z.ostring().nullable(),
	accessGridRowObject: z.any().nullable(),
});

/** @see Manzanita.Web.Areas.Admin.Models.UserModel */
export const userModelSchema = z.object({
	id: z.number(),
	userName: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string().email(),
	activeFromDate: localDateTimeSchema.nullable(),
	activeUntilDate: localDateTimeSchema.nullable(),
	lastSignInDate: localDateTimeSchema.nullable(),
	lastOrderDate: localDateTimeSchema.nullable(),
	deleted: z.boolean().nullable(),
	countryNameDisplay: z.string().nullable(),
	countryCode: z.string().nullable(),
	cultureCode: z.string().nullable(),
	userRole: z.string().nullable(),
	editMode: z.boolean(),
	currencyIsoCode: z.string().nullable(),
	orderValueLimit: z.onumber().nullable(), // Channel only
	isLocked: z.boolean(),
	userAssociations: z.array(accessTypeModelSchema),
});
