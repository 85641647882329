import axios from 'axios';

import { fiscalCalendarSchema, fiscalCalendarYearSchema } from './models';
import type { FiscalCalendar, FiscalCalendarPeriod, FiscalCalendarYear } from './models';

/** AppleRetail.Web.Areas.Admin.ApiControllers.FiscalCalendarApiController*/
export async function getAll(signal?: AbortSignal): Promise<FiscalCalendarYear[]> {
	const response = await axios.get<unknown>('/api/fiscal-calendar/all', { signal });
	return fiscalCalendarYearSchema.array().parse(response.data);
}

/** AppleRetail.Web.Areas.Admin.ApiControllers.FiscalYearApiController */
export async function getNewNewFiscalYear(signal?: AbortSignal): Promise<FiscalCalendar> {
	const response = await axios.get<unknown>('/api/fiscal-year', { signal });
	return fiscalCalendarSchema.parse(response.data);
}

/** AppleRetail.Web.Areas.Admin.ApiControllers.FiscalCalendarApiController*/
export async function addFiscalCalendar(fiscalPeriods: FiscalCalendarPeriod[]): Promise<void> {
	await axios.post(`/api/fiscal-calendar`, fiscalPeriods);
}

/** AppleRetail.Web.Areas.Admin.ApiControllers.FiscalCalendarApiController*/
export async function get(fiscalYear: number): Promise<FiscalCalendar> {
	const response = await axios.get<unknown>(`/api/fiscal-calendar/${fiscalYear}`);
	return fiscalCalendarSchema.parse(response.data);
}
