import { Tabs } from '@mantine/core';

import { ChangePassword } from '@apple/features/user/components/ChangePassword';
import { ProfileForm } from '@apple/features/user/components/ProfileForm';
import { useTranslation } from '@apple/lib/i18next';
import type { ChangePasswordRequest, UpdateProfileRequest } from '@apple/features/auth';
import type { Profile } from '@apple/features/auth-retail/models/user';
import type {
	CountryOption,
	CurrencyOption,
	LanguageOption,
	TimeZoneOption,
} from '@apple/utils/globalization';

export type AccountSettingsProps = {
	profile: Profile;
	timeZones: TimeZoneOption[];
	languages: LanguageOption[];
	currencies: CurrencyOption[];
	countries: CountryOption[];
	updateProfile: (request: UpdateProfileRequest) => Promise<void>;
	changePassword: (request: ChangePasswordRequest) => Promise<void>;
	showSuccessMessage: boolean;
	setShowSuccessMessage: (state: boolean) => void;
};

export function AccountSettings({
	profile,
	countries,
	currencies,
	languages,
	timeZones,
	updateProfile,
	changePassword,
	showSuccessMessage,
	setShowSuccessMessage,
}: AccountSettingsProps) {
	const { t } = useTranslation('profile');

	return (
		<Tabs defaultValue='profile'>
			<Tabs.List>
				<Tabs.Tab value='profile'>{t('tabs.userProfile')}</Tabs.Tab>
				<Tabs.Tab value='change-password'>{t('tabs.passwordReset')}</Tabs.Tab>
			</Tabs.List>
			<Tabs.Panel value='profile'>
				<ProfileForm
					profile={profile}
					onSubmit={updateProfile}
					timeZones={timeZones}
					languages={languages}
					currencies={currencies}
					countries={countries}
					showSuccessMessage={showSuccessMessage}
					setShowSuccessMessage={setShowSuccessMessage}
				/>
			</Tabs.Panel>
			<Tabs.Panel value='change-password'>
				<ChangePassword onSubmit={changePassword} />
			</Tabs.Panel>
		</Tabs>
	);
}
