import { z } from 'zod';

import { userModelSchema } from '@apple/features/user/models/management';
import { countryCodeSchema, dateTimeSchema } from '@apple/utils/globalization/models';
import type {
	UserAllAssociation,
	UserAssociation,
	UserCountryAssociation,
	UserPlantAssociation,
} from '@apple/features/user/models/associations';
import type { UserModel } from '@apple/features/user/models/management';

export type UserAssociationType = 'all' | 'plant' | 'country' | 'market' | 'store';

/** @see AppleRetail.Data.Profile.UserCustomerGroupAssociation */
export interface UserMarketAssociation extends UserAssociation {
	customerGroupId: number;
}

/** @see AppleRetail.Data.Profile.UserCustomerAssociation */
export interface UserStoreAssociation extends UserAssociation {
	customerId: number;
}

export const userAssociationSchema = z.object({
	userId: z.number(),
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean().catch(false),
});

/** @see AppleBrandedPrograms.Profile.UserAllAssociation */
export const userAllAssociationSchema = userAssociationSchema as z.ZodType<UserAllAssociation>;

/** @see AppleBrandedPrograms.Profile.UserPlantAssociation */
export const userPlantAssociationSchema = userAssociationSchema.extend({
	plantId: z.number(),
}) as z.ZodType<UserPlantAssociation>;

/** @see AppleBrandedPrograms.Profile.UserCountryAssociation */
export const userCountryAssociationSchema = userAssociationSchema.extend({
	countryCode: countryCodeSchema,
}) as z.ZodType<UserCountryAssociation>;

/** @see AppleRetail.Data.Profile.UserCustomerGroupAssociation */
export const userMarketAssociationSchema = userAssociationSchema.extend({
	customerGroupId: z.number(),
}) as z.ZodType<UserMarketAssociation>;

/** @see AppleRetail.Data.Profile.UserCustomerAssociation */
export const userStoreAssociationSchema = userAssociationSchema.extend({
	customerId: z.number(),
}) as z.ZodType<UserStoreAssociation>;

/**
 * Form data for a user. Maps some properties due to server validation model differences.
 * @property {string} language - Maps to user culture.
 */
export interface UserFormData
	extends Omit<
		UserModel,
		'userAssociations' | 'email' | 'countryCode' | 'cultureCode' | 'userRole'
	> {
	emailAddress: string;
	defaultCountry: string;
	language: string;
	userRole: string;
	allAssociation: UserAllAssociation | null;
	plantAssociations: UserPlantAssociation[];
	countryAssociations: UserCountryAssociation[];
	marketAssociations: UserMarketAssociation[];
	storeAssociations: UserStoreAssociation[];
}

export const userFormDataSchema = userModelSchema
	.omit({ userAssociations: true, email: true, countryCode: true, cultureCode: true })
	.extend({
		emailAddress: userModelSchema.shape.email,
		defaultCountry: z.string(),
		language: z.string(),
		userRole: z.string(),
		allAssociation: userAllAssociationSchema.nullable(),
		plantAssociations: z.array(userPlantAssociationSchema),
		countryAssociations: z.array(userCountryAssociationSchema),
		marketAssociations: z.array(userMarketAssociationSchema),
		storeAssociations: z.array(userStoreAssociationSchema),
	});

/** @see AppleRetail.Web.Features.Profiles.UserManagementController.UserDialogViewModel */
export type UserManagementOptions = z.infer<typeof userManagementOptionsSchema>;

/** @see AppleRetail.Web.Features.Profiles.UserManagementController.UserDialogViewModel */
export const userManagementOptionsSchema = z.object({
	visibleAccessTypes: z.array(z.string()),
	userCurrencySelectionEnabled: z.boolean(),
	languageSelectionEnabled: z.boolean(),
	characterWhitelist: z.string().nullable(),
});
