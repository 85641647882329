import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getMarket, getMarkets } from './api';
import type { MarketId } from './models';

export const marketQueries = {
	getMarket: (marketId: MarketId) =>
		queryOptions({
			queryKey: ['market', marketId],
			queryFn: ({ signal }) => getMarket(Number(marketId), signal),
			staleTime: 5 * 60 * 1000, // 5 minutes
		}),
	getMarkets: () =>
		queryOptions({
			queryKey: ['markets', 'list'],
			queryFn: ({ signal }) => getMarkets(signal),
			placeholderData: keepPreviousData,
			staleTime: 5 * 60 * 1000, // 5 minutes
			initialDataUpdatedAt: 0,
			initialData: [],
		}),
} as const;
