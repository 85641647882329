import { Box, Grid, Stack } from '@mantine/core';

import { UserAnnouncements } from '@apple/features/announcements/components/UserAnnouncements';
import { useUserAnnouncements } from '@apple/features/announcements/hooks/useUserAnnouncements';
import { Dashboard } from '@apple/ui/shell/components/Dashboard';
import type { DashboardCard } from '@apple/ui/shell/components/Dashboard';

type Props = {
	cards: DashboardCard[];
};

export function AdminDashboard({ cards }: Props) {
	const userAnnouncements = useUserAnnouncements();

	return (
		<Grid gutter={{ base: 5, xs: 'md', md: 'xl', xl: 10 }}>
			<Grid.Col span={{ base: 12, lg: 6 }}>
				<Stack>
					<Dashboard cards={cards} />
					{!userAnnouncements.isLoading &&
						!userAnnouncements.error &&
						userAnnouncements.data && (
							<Box py='sm' px='md' mx='xl'>
								<UserAnnouncements announcementList={userAnnouncements.data} />
							</Box>
						)}
				</Stack>
			</Grid.Col>
		</Grid>
	);
}
