import { queryOptions } from '@tanstack/react-query';

import type { UserManagementOptions } from '@apple/features/user-retail/models/management';

import { getUser, getUserManagementOptions } from '../api/management';

export const userManageQueries = {
	options: queryOptions({
		queryKey: ['user-management', 'options'],
		queryFn: ({ signal }) => getUserManagementOptions(signal),
		staleTime: 1000 * 60 * 60, // 1 hour
		initialDataUpdatedAt: 0,
		initialData: {
			visibleAccessTypes: [],
			userCurrencySelectionEnabled: false,
			languageSelectionEnabled: false,
			characterWhitelist: null,
		} satisfies UserManagementOptions,
		throwOnError: true,
	}),

	user: (userName: string) =>
		queryOptions({
			queryKey: ['user', userName],
			queryFn: ({ signal }) => getUser(userName, signal),
			staleTime: 5 * 60 * 1000, // 5 minutes
			throwOnError: true,
		}),
};
