import { createContext } from 'react';

import type { AuthContextBase } from '@apple/features/auth/contexts/auth-context';

import type { Profile } from '../models/user';

export type AuthContext = AuthContextBase<Profile> & {
	canAccessMultipleStores: boolean;
};

export const AuthContext = createContext<AuthContext | undefined>(undefined);
