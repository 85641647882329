import { z } from 'zod';

import { marketIdSchema } from '@apple/features/market';
import { associationSchema } from '@apple/features/product/models/associations';
import type {
	CustomerGroupAssociation,
	ProductAssociation,
} from '@apple/features/product/models/associations';

export type ProductAssociationType = 'all' | 'plant' | 'country' | 'market' | 'store';

export interface MarketAssociation extends CustomerGroupAssociation {}
export interface StoreAssociation extends ProductAssociation {
	customerId: number;
}

export const marketAssociationSchema = associationSchema.extend({
	customerGroupId: marketIdSchema,
}) satisfies z.ZodType<MarketAssociation>;
export const storeAssociationSchema = associationSchema.extend({
	customerId: z.number(),
}) satisfies z.ZodType<StoreAssociation>;
